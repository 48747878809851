















import { Component, Prop, Inject as VueInject, Mixins } from 'vue-property-decorator'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../support'
import { IToastMixin, ToastMixin } from '../../../shared'

import { IProductsRepository, ProductsRepositoryType } from '../../contracts/repositories'
import { BundledItem, BundledItemProps } from '../../../shared/molecules/BundledItem'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<BundledProductsList>({
  name: 'BundledProductsList',
  components: { BundledItem },
  mounted (): void {
    this.loadProducts()
  }
})
export class BundledProductsList extends Mixins<IToastMixin>(ToastMixin) {
  @Prop({ type: Array, required: true })
  protected readonly skus!: string[]

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  public isLoading: boolean = false
  public products: Array<Omit<BundledItemProps, 'uid' | 'discount'>> = []

  public get hasProducts (): boolean {
    return Array.isArray(this.products) && this.products.length > 0
  }

  /**
   * Loads the products by their sku
   */
  protected async loadProducts (): Promise<void> {
    try {
      this.isLoading = true
      const rawProducts = await this.productsRepository.loadProductsBySkus(this.skus)

      this.products = rawProducts.map((product) => {
        const variant = Object.values(product.variants).filter((variant) => this.skus.includes(variant.sku))
        return {
          isAvailable: variant[0].isAvailable,
          sellableQuantity: variant[0].sellableQuantity,
          sku: variant[0].sku,
          price: variant[0].price.regularPrice,
          product
        }
      })

      this.$emit('bundleProductsLoaded', this.products)
    } catch (e) {
      this.showToast((e as Error).message, 'danger')
    } finally {
      this.isLoading = false
    }
  }
}

export default BundledProductsList
