

















































































import { Component, Prop, PropSync, Vue, Inject as VueInject } from 'vue-property-decorator'

import { calculateDiscount, defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'
import { Price } from '../../../../../contexts'

import { MilesAndMoreCounter } from '../../../../loyalty/molecules/MilesAndMoreCounter'

import BundledProductsList from '../../BundledProductsList/BundledProductsList.vue'

import { Variant, VariantsSwitchProps } from '../VariantsSwitch.contracts'
import { CartModel } from '../../../../checkout/contracts/models'
import { BenefitProgram } from '../../../../loyalty/contracts/programs'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<Advanced>({
  name: 'Advanced',
  components: { BundledProductsList, MilesAndMoreCounter }
})
export class Advanced extends Vue {
  @PropSync('model', { required: false, default: null })
  public _model!: VariantsSwitchProps['model']

  @Prop({ required: false, default: null })
  public readonly cart!: CartModel | null

  @Prop({ required: false })
  public readonly loyaltyPayload?: Record<BenefitProgram, Record<string, unknown>>

  @Prop({ type: Array, required: false })
  public variants?: Variant[]

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isFaF!: boolean

  @Prop({ type: Array, required: false, default: null })
  public readonly bundleSkus!: Array<string> | null

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  private readonly isMobile!: () => boolean

  /**
   * Get variants with bestRatio identifier
   */
  public get bestLabelVariants () {
    const withRatio = []

    if (!this.variants) {
      return
    }

    for (const variant of Object.values(this.variants)) {
      if (!variant.price) {
        return
      }

      withRatio.push({
        identifierLabel: variant.identifierLabel,
        ratio: (variant.price.finalPrice / Number(String(variant.identifierLabel).split('-')[0])).toFixed(2)
      })
    }

    const bestRatioVariant = withRatio.sort((a, b) => Number(a.ratio) < Number(b.ratio) ? -1 : 1)

    return this.variants.map((item) => {
      return {
        ...item,
        hasBestRatio: item.identifierLabel === bestRatioVariant[0].identifierLabel
      }
    })
  }

  public calculatedDiscount (price: Price): string {
    return calculateDiscount(price.finalPrice, price.regularPrice)
  }

  public getVariantPriceWithoutDelivery (price: Price): number {
    return price.finalPrice - ((this.cart && this.cart.selectedShippingMethod) ? this.cart.selectedShippingMethod.price.value : 0)
  }

  protected onModelChange (slug: string): void {
    if (this._model === slug) {
      return
    }

    this._model = slug
  }
}

export default Advanced
